import ApiService from './ApiService'

export const getTemplateApi = ({
    type,
    dateFrom,
    dateTo,
}: {
    type: string
    dateFrom: string
    dateTo: string
}) => {
    const searchParams = new URLSearchParams()
    if (dateFrom) searchParams.append('dateFrom', dateFrom)
    if (dateTo) searchParams.append('dateTo', dateTo)

    return ApiService.fetchData<any>({
        url: `/survey/template/${type}/?${searchParams.toString()}`,
        method: 'GET',
    })
}
export const getTotalSatisfiedApi = ({
    type,
    dateFrom,
    dateTo,
}: {
    type: string
    dateFrom: string
    dateTo: string
}) => {
    const searchParams = new URLSearchParams()
    if (dateFrom) searchParams.append('dateFrom', dateFrom)
    if (dateTo) searchParams.append('dateTo', dateTo)

    return ApiService.fetchData<any>({
        url: `/survey/totalSatisfied/${type}/?${searchParams.toString()}`,
        method: 'GET',
    })
}
export const getSurveyRatingApi = ({
    type,
    dateFrom,
    dateTo,
}: {
    type: string
    dateFrom: string
    dateTo: string
}) => {
    const searchParams = new URLSearchParams()
    if (dateFrom) searchParams.append('dateFrom', dateFrom)
    if (dateTo) searchParams.append('dateTo', dateTo)

    return ApiService.fetchData<any>({
        url: `/survey/surveyRating/${type}/?${searchParams.toString()}`,
        method: 'GET',
    })
}
