import { Role } from "@@types/roles";
import { lazy } from "react";

const SurveyDashboardApp = lazy(() => import("./SurveyDashboardApp"));

/**
 * The chat app config.
 */
const SurveyDashboardAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "survey-dashboard",
      element: <SurveyDashboardApp />,
      auth: [Role.DASHBOARD],
    },
  ],
};

export default SurveyDashboardAppConfig;
