import { FuseNavItemType } from "@fuse/core/FuseNavigation/types/FuseNavItemType";

import { Role } from "@@types/roles";

// i18next.addResourceBundle("en", "navigation", en);
// i18next.addResourceBundle("tr", "navigation", tr);
// i18next.addResourceBundle("ar", "navigation", ar);

/**
 * The navigationConfig object is an array of navigation items for the Fuse application.
 */
const navigationConfig: FuseNavItemType[] = [
  {
    id: "dashboard",
    title: "Dashboard",
    type: "collapse",
    icon: "dashboard",
    translate: "nav.dashboard",
    auth: [Role.DASHBOARD],
    children: [
      {
        id: "apps.dashboard",
        title: "Dashboard",
        type: "item",
        icon: "dashboard",
        url: "/dashboard",
        translate: "nav.dashboard",
        auth: [Role.DASHBOARD],
      },
      {
        id: "apps.surveyDashboard",
        title: "Survey Dashboard",
        type: "item",
        icon: "dashboard",
        url: "/survey-dashboard",
        translate: "nav.surveyDashboard",
        auth: [Role.DASHBOARD],
      },
    ],
  },
  {
    id: "apps.messenger",
    title: "Messenger",
    type: "item",
    icon: "chat",
    url: "/chat",
    translate: "nav.chat",
    auth: [Role.ALL_CONVERSATIONS, Role.ONLY_ASSIGNED_CONVERSATIONS],
  },
  {
    id: "apps.broadcast",
    title: "Broadcast",
    type: "collapse",
    icon: "broadcast",
    translate: "nav.broadcast",
    auth: [Role.BULK_MESSAGES],

    children: [
      {
        id: "apps.broadcastItem",
        title: "Broadcast",
        type: "item",
        url: "/broadcast",
        icon: "broadcastPage",

        translate: "nav.broadcast",
        end: true,
      },
      {
        id: "apps.contacts-groups",
        title: "Contacts groups",
        type: "item",
        url: "/contacts-groups",
        icon: "contactsGroups",

        translate: "nav.contactsGroups",
        end: true,
      },
    ],
  },

  {
    id: "apps.appointment",
    title: "Appointment",
    type: "item",
    icon: "appointment",
    url: "/appointment",
    translate: "nav.appointment",
    auth: [Role.APPOINTMENT],
  },
  {
    id: "apps.companyMessages",
    title: "Company Messages",
    type: "collapse",
    icon: "companyMessages",
    translate: "nav.companyMessages",
    auth: [Role.MESSAGES_COMPANY],

    children: [
      {
        id: "apps.companyMessages.text",
        title: "Company Messages Text",
        type: "item",
        url: "/companyMessages/text",

        translate: "nav.text",
        end: true,
      },
      {
        id: "apps.companyMessages.audio",
        title: "Company Messages Audio",
        type: "item",
        url: "/companyMessages/audio",

        translate: "nav.audio",
        end: true,
      },
      {
        id: "apps.companyMessages.contact",
        title: "Company Messages contact",
        type: "item",
        url: "/companyMessages/contact",

        translate: "nav.contact",
        end: true,
      },
      {
        id: "apps.companyMessages.image",
        title: "Company Messages image",
        type: "item",
        url: "/companyMessages/image",

        translate: "nav.image",
        end: true,
      },
      {
        id: "apps.companyMessages.video",
        title: "Company Messages video",
        type: "item",
        url: "/companyMessages/video",

        translate: "nav.video",
        end: true,
      },
      {
        id: "apps.companyMessages.document",
        title: "Company Messages document",
        type: "item",
        url: "/companyMessages/document",

        translate: "nav.document",
        end: true,
      },
      {
        id: "apps.companyMessages.location",
        title: "Company Messages Location",
        type: "item",
        url: "/companyMessages/location",

        translate: "nav.location",
        end: true,
      },
      {
        id: "apps.companyMessages.interactive",
        title: "Company Messages Interactive",
        type: "item",
        url: "/companyMessages/interactive",

        translate: "nav.interactive",
        end: true,
      },
      {
        id: "apps.companyMessages.template",
        title: "Company Messages Template",
        type: "item",
        url: "/companyMessages/template",

        translate: "nav.template",
        end: true,
      },
    ],
  },
  {
    id: "apps.messages",
    title: "Messages",
    type: "item",
    icon: "messages",
    url: "/messages",
    translate: "nav.messages",
    auth: [Role.MESSAGES_PAGE],
  },
  {
    id: "apps.contacts",
    title: "Contacts",
    type: "item",
    icon: "contacts",
    url: "/contacts",
    translate: "nav.contacts",
    auth: [Role.CONTACTS],
  },
  {
    id: "apps.consultations",
    title: "Consultations",
    type: "item",
    icon: "consultations",
    url: "/consultations",
    translate: "nav.consultations",
    auth: [Role.CONSULTATION_PAGE],
  },
  {
    id: "apps.team",
    title: "Team",
    type: "item",
    icon: "users",
    url: "/team",
    translate: "nav.teamManagement",
    auth: [Role.TEAM_MANAGEMENT],
  },

  {
    id: "apps.bot",
    title: "Bot",
    type: "item",
    icon: "bot",
    url: "/bot",
    translate: "nav.bot",
    auth: [Role.BOT_PAGE],
  },
  {
    id: "apps.gpt",
    title: "Gpt",
    type: "item",
    icon: "gpt",
    url: "/gpt",
    translate: "nav.gpt",
    auth: [Role.GPT_PAGE],
  },
  {
    id: "apps.settings",
    title: "Settings",
    type: "collapse",
    icon: "settings",
    translate: "nav.settings",
    auth: [Role.ADMIN, Role.BOT_PAGE],
    children: [
      {
        id: "apps.advancedSettings",
        title: "Advanced Settings",
        type: "item",
        // icon: "settings",
        url: "/advanced-settings",
        translate: "nav.advancedSettings",
        auth: [Role.ADMIN],
      },
      {
        id: "apps.chatBotSettings",
        title: "ChatBot Settings",
        type: "item",
        // icon: "settings",
        url: "/chatbot-settings",
        translate: "nav.chatbotSettings",
        auth: [Role.BOT_PAGE],
      },
    ],
  },
];

export default navigationConfig;
