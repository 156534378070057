import { combineSlices } from "@reduxjs/toolkit";
import { fuseSettingsSlice } from "@fuse/core/FuseSettings/fuseSettingsSlice";
import { i18nSlice } from "app/store/i18nSlice";
import apiService from "./apiService";
import { sessionSlice } from "./auth/sessionSlice";
import { userSlice } from "./slices/userSlice";
import { chatSlice } from "./slices/chat";
import RtkQueryService from "@@services/RtkQueryService";
import { AppointmentSlice } from "./slices/appointment";
import { botSlice } from "./slices/bot";
import { broadcastSlice } from "./slices/broadcast";
import { companyMessagesSlice } from "./slices/companyMessages";
import { contactsGroupsSlice } from "./slices/contactsGroups";
import { contactsSlice } from "./slices/contactsSlice";
import { dashboardSlice } from "./slices/dashboard";
import { flowSlice } from "./slices/flow";
import { gptSlice } from "./slices/gpt";
import { messagesSlice } from "./slices/messages";
import { settingsSlice } from "./slices/settings";
import { teamSlice } from "./slices/teamListSlice";
import { CompanyProfileSlice } from "./slices/companyProfile";
import { teamChatSlice } from "./slices/teamChat";
import { surveyDashboardSlice } from "./slices/surveyDashboard";
import { botSettingsSlice } from "./slices/botSettings";

// eslint-disable-next-line
// @ts-ignore
export interface LazyLoadedSlices {}

// `combineSlices` automatically combines the reducers using
// their `reducerPath`s, therefore we no longer need to call `combineReducers`.
export const rootReducer = combineSlices(
  /**
   * Static slices
   */
  userSlice,
  fuseSettingsSlice,
  i18nSlice,
  sessionSlice,
  chatSlice,
  AppointmentSlice,
  botSlice,
  broadcastSlice,
  companyMessagesSlice,
  contactsGroupsSlice,
  contactsSlice,
  dashboardSlice,
  flowSlice,
  gptSlice,
  messagesSlice,
  settingsSlice,
  teamSlice,
  CompanyProfileSlice,
  teamChatSlice,
  surveyDashboardSlice,
  botSettingsSlice,
  /**
   * Dynamic slices
   */
  {
    [apiService.reducerPath]: apiService.reducer,
    [RtkQueryService.reducerPath]: RtkQueryService.reducer,
  }
).withLazyLoadedSlices<LazyLoadedSlices>();
