import {
  getSurveyRatingApi,
  getTemplateApi,
  getTotalSatisfiedApi,
} from "@@services/SurveyDashboardService";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

export type SurveyDashboardState = {
  // loading: boolean
  surveyType: string;
  templateMsg?: {
    total: number;
    notHaveWhatsapp: number;
    read: number;
    delivered: number;
    replyMsgs: number;
  };
  totalSatisfied?: {
    verySatisfied: number;
    satisfied: number;
    notSatisfied: number;
  };
  satisfied?: {
    verySatisfied: number;
    satisfied: number;
    notSatisfied: number;
  };
  surveyRating?: {
    [key: string]: number;
  };
};
export const getTemplate = createAsyncThunk(
  "surveyDashboard/getTemplate",
  async (data: { dateFrom: string; dateTo: string }, { getState }) => {
    const type = surveyType(getState() as any);
    const response = await getTemplateApi({ ...data, type });
    return response.data;
  }
);
export const getTotalSatisfied = createAsyncThunk(
  "surveyDashboard/getTotalSatisfied",
  async (data: { dateFrom: string; dateTo: string }, { getState }) => {
    const type = surveyType(getState() as any);
    const response = await getTotalSatisfiedApi({ ...data, type });
    return response.data;
  }
);
export const getSatisfied = createAsyncThunk(
  "surveyDashboard/getSatisfied",
  async (data: { dateFrom: string; dateTo: string }, { getState }) => {
    const type = surveyType(getState() as any);
    const response = await getTotalSatisfiedApi({ ...data, type });
    return response.data;
  }
);
export const getSurveyRating = createAsyncThunk(
  "surveyDashboard/getSurveyRating",
  async (data: { dateFrom: string; dateTo: string }, { getState }) => {
    const type = surveyType(getState() as any);
    const response = await getSurveyRatingApi({ ...data, type });
    return response.data;
  }
);
const initialState: SurveyDashboardState = {
  surveyType: "1",
};
export const surveyDashboardSlice = createSlice({
  name: "surveyDashboardSlice",
  initialState,
  reducers: {
    setSurveyType(state, action: PayloadAction<string>) {
      state.surveyType = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getTemplate.fulfilled, (state, action) => {
        state.templateMsg = action.payload;
      })
      .addCase(getTotalSatisfied.fulfilled, (state, action) => {
        state.totalSatisfied = action.payload;
      })
      .addCase(getSatisfied.fulfilled, (state, action) => {
        state.satisfied = action.payload;
      })
      .addCase(getSurveyRating.fulfilled, (state, action) => {
        state.surveyRating = action.payload;
      });
  },
});

export const surveyType = (state: RootState) =>
  state.surveyDashboardSlice.surveyType;
export const templateMsg = (state: RootState) =>
  state.surveyDashboardSlice.templateMsg;
export const totalSatisfied = (state: RootState) =>
  state.surveyDashboardSlice.totalSatisfied;
export const satisfied = (state: RootState) =>
  state.surveyDashboardSlice.satisfied;
export const surveyRating = (state: RootState) =>
  state.surveyDashboardSlice.surveyRating;

export const { setSurveyType } = surveyDashboardSlice.actions;

export default surveyDashboardSlice.reducer;
